import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  Inject,
  inject,
  makeStateKey,
  PLATFORM_ID,
  TransferState,
  ViewChild
} from '@angular/core';
import { IFooterItem } from '@cinetixx/cinetixx-booking-ui';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AbstractBasePage } from './core/abstracts';

declare var dataLayer: any[];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  @ViewChild('cookies') cookies: ElementRef<HTMLElement>;

  public currentPage: string;
  public static instance: AppComponent;
  public readonly navItems: { text: string; link?: string; subItems?: { text: string; link: string; }[]; }[] = [
    {
      text: 'Startseite',
      link: '/'
    },
    {
      text: 'Filme',
      subItems: [
        {
          text: 'Aktuell im Kino',
          link: '/filme/aktuell-im-kino'
        },
        {
          text: 'Demnächst',
          link: '/filme/demnaechst'
        },
        {
          text: 'Filmübersicht',
          link: '/filme/filmuebersicht'
        },
      ]
    },
    {
      text: 'Kinos',
      link: '/kinos'
    }
  ];
  public readonly footerItems: IFooterItem[] = [
    {
      title: 'Contact',
      subItems: [
        {
          text: '+49 (0) 89 / 552777-60',
          link: 'tel:+49 (0) 89 / 552777-60'
        },
        {
          text: 'vertrieb@cinetixx.de',
          link: 'mailto:vertrieb@cinetixx.de'
        }
      ]
    },
    {
      title: 'Rechtliches',
      subItems: [
        {
          text: 'AGBS',
          link: 'https://cinetixx.de/agbs.html',
        },
        {
          text: 'Datenschutz',
          link: 'https://cinetixx.de/datenschutz.html',
        },
        {
          text: 'Impressum',
          link: 'https://cinetixx.de/impressum.html',
        },
        {
          text: 'Cookie Einstellungen',
          isButton: true,
          id: 'open-cookies-settings'
        }
      ]
    },
    {
      title: 'Filme',
      subItems: [
        {
          text: 'Aktuell im Kino',
          link: '/filme/aktuell-im-kino'
        },
        {
          text: 'Demnächst',
          link: '/filme/demnaechst'
        },
        {
          text: 'Filmübersicht',
          link: '/filme/filmuebersicht'
        },
      ]
    },
    {
      title: 'Kinos',
      subItems: [
        {
          text: 'Kinos',
          link: '/kinos'
        }
      ]
    },
    {
      title: 'Index',
      subItems: [
        {
          text: 'Film-Index',
          link: '/index/film-index/A'
        },
        {
          text: 'Kino-Index',
          link: '/index/kino-index/A'
        },
        {
          text: 'Darsteller-Index',
          link: '/index/darsteller-index/A'
        },
        {
          text: 'Produktion-Index',
          link: '/index/produktion-index/A'
        },
      ]
    }
  ];

  private readonly _transferState = inject(TransferState);
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _currentPageStateKey = makeStateKey<string>('currentPage');

  public constructor(
    @Inject(PLATFORM_ID) protected readonly platformId: Object
  ) {
    AppComponent.instance = this;
  }

  public ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.dataLayer = window.dataLayer || [];

      setGtag(false, 'default');

      function gtag() {
        dataLayer.push(arguments);
      }

      if (this.cookies) {
        const analyticsCookie = (analyticsCookie) => {
          setGtag(analyticsCookie, 'update');
        };

        const functionalCookie = (functionalCookie) => {
          if (functionalCookie) {

          }
        };

        const cookiesActions = (cookiesPreferences) => {
          analyticsCookie(cookiesPreferences.analyticsCookie);
          functionalCookie(cookiesPreferences.functionalCookie);
        };

        if (this.cookies.nativeElement['areCookiesPreferencesSet']) {
          cookiesActions(this.cookies.nativeElement['cookiesPreferences']);
        }

        this.cookies.nativeElement.addEventListener('cookiesBuilt', () => location.reload());
      }

      function setGtag(statistics, type) {
        // @ts-ignore
        gtag('consent', type, {
          'analytics_storage': statistics ? 'granted' : 'denied',
          'ad_storage': statistics ? 'granted' : 'denied'
        });
      }
    }
  }

  public activateRoute(cmp: AbstractBasePage): void {
    if (isPlatformServer(this.platformId)) {
      this.currentPage = cmp.hostElementRef.nativeElement.tagName.toLowerCase().replace('app-', '');
      this._transferState.set(this._currentPageStateKey, this.currentPage);
      this._cdr.detectChanges();
    } else if (isPlatformBrowser(this.platformId)) {
      this.currentPage = this._transferState.get(this._currentPageStateKey, '');
      this._cdr.detectChanges();
    }
  }
}
