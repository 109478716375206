import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContributorFullNamePipe, ContributorUrlPipe, DateFormatPipe, GenreStringPipe, StripTagsPipe } from './pipes';


@NgModule({
  declarations: [
    DateFormatPipe,
    GenreStringPipe,
    ContributorFullNamePipe,
    ContributorUrlPipe,
    StripTagsPipe
  ],
  exports: [
    DateFormatPipe,
    GenreStringPipe,
    ContributorFullNamePipe,
    ContributorUrlPipe,
    StripTagsPipe
  ],
  imports: [
    CommonModule,
  ],
})
export class CoreModule { }
