import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'filme',
    loadChildren: () => import('./movies/movies.module').then(m => m.MoviesModule)
  },
  {
    path: 'kinos',
    loadChildren: () => import('./cinemas/cinemas.module').then(m => m.CinemasModule)
  },
  {
    path: 'index',
    loadChildren: () => import('./indexes/indexes.module').then(m => m.IndexesModule)
  },
  {
    path: 'mitwirkende',
    loadChildren: () => import('./contributors/contributors.module').then(m => m.ContributorsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
