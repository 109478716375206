import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CinetixxBookingUiModule, FooterModule, HeaderModule, Slug } from '@cinetixx/cinetixx-booking-ui';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import extraDe from '@angular/common/locales/extra/de';
import { UiModule } from './ui/ui.module';

registerLocaleData(de, 'main', extraDe)

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    CinetixxBookingUiModule.forRoot({
      postersUrl: environment.postersUrl,
      bookingUrl: environment.bookingUrl,
      baseUrl: environment.baseUrl,
      getMovieUrlFn(id: number, name: string): string {
        return `/filme/${id}-${Slug.getSlugFromText(name)}`;
      }
    }),
    FooterModule,
    HeaderModule,
    HttpClientModule,
    UiModule,
  ],
  providers: [
    provideClientHydration(),
    {
      provide: LOCALE_ID,
      useValue: 'main'
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
