import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenMovieDatabaseButtonComponent } from './open-movie-database-button/open-movie-database-button.component';
import { ContributorItemComponent } from './contributor-item/contributor-item.component';
import { CoreModule as CxCoreModule } from '@cinetixx/cinetixx-booking-ui';
import { CoreModule } from '../core/core.module';
import { SchemaOrgCinemaPostalAddressComponent } from './schema-org-cinema-postal-address/schema-org-cinema-postal-address.component';
import { ExpandTextComponent } from './expand-text/expand-text.component';
import { MoviesDescriptionsTextComponent } from './movies-descriptions-text/movies-descriptions-text.component';



@NgModule({
  declarations: [
    OpenMovieDatabaseButtonComponent,
    ContributorItemComponent,
    SchemaOrgCinemaPostalAddressComponent,
    ExpandTextComponent,
    MoviesDescriptionsTextComponent,
  ],
  exports: [
    OpenMovieDatabaseButtonComponent,
    ContributorItemComponent,
    SchemaOrgCinemaPostalAddressComponent,
    ExpandTextComponent,
    MoviesDescriptionsTextComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    CxCoreModule
  ]
})
export class UiModule { }
