import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-expand-text',
  templateUrl: './expand-text.component.html',
  styleUrls: ['./expand-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandTextComponent {

  public isActive = true;
}
