<div class="page-wrapper page-wrapper--{{ currentPage }}">
  <cx-header>
    <ul class="d-flex align-items-center full-height p2">
      <li class="full-height position-relative" *ngFor="let item of navItems">
        <a
          [href]="item.link"
          class="h5 semibold d-flex align-items-center full-height"
          *ngIf="!item.subItems"
          [title]="item.text"
        >
          {{ item.text }}
        </a>

        <ng-container *ngIf="item.subItems">
          <button class="h5 semibold d-flex align-items-center full-height cursor-pointer">
            <span>{{ item.text }}</span>
            <i class="ri-arrow-drop-down-line"></i>
          </button>

          <ol class="position-absolute p-16 white-space-nowrap">
            <li *ngFor="let subItem of item.subItems">
              <a [href]="subItem.link" class="p-8 d-block" [title]="subItem.text">{{ subItem.text }}</a>
            </li>
          </ol>
        </ng-container>
      </li>
    </ul>
  </cx-header>

  <div class="page-wrapper__page-content m-t-64">
    <router-outlet (activate)="activateRoute($event)"></router-outlet>

    <div class="container">
      <app-expand-text class="d-block m-t-48 p-t-48 bottom-text">
        <h2 class="p1">
          cinetixx Filme - Ihre Quelle für filmische Inspiration!
        </h2>

        <h3 class="m-t-16 p2">
          Film-Datenbank
        </h3>
        <p class="p3 m-t-8">
          Unsere umfangreiche Filmdatenbank ist eine wahre Fundgrube für Filmliebhaber. Sie enthält nicht nur Kinoklassiker, die das Publikum seit langem begeistern, sondern auch die neuesten Produktionen, die die Kunst des Filmemachens weiter voranbringen. Ob Sie nun epische Meisterwerke mit großen Budgets oder subtile, intime Independent-Filme bevorzugen, unsere Datenbank bietet eine Fülle von Inhalten, die Ihr Herz und Ihren Geist berühren werden.

          Beim Durchstöbern unserer Angebote haben Sie die Möglichkeit, eine Vielzahl von Filmgenres zu entdecken, von Dramen über Komödien und Horrorfilme bis hin zu Romanzen. Auch die Erkundung verschiedener Regiestile kommt nicht zu kurz, von klassischen Erzählungen bis hin zu Experimenten mit Form und Inhalt.

          Wir wollen, dass unsere Plattform mehr ist als nur ein Ort, an dem man beliebte Hollywood-Hits findet. Natürlich gibt es auch diese, aber darüber hinaus bemühen wir uns, Meisterwerke des unabhängigen Kinos zu zeigen, die von den Mainstream-Medien oft nicht gewürdigt werden. Aus diesem Grund ist cinetixx Filme ein Ort, der eine Fülle von Perspektiven und Möglichkeiten für alle Filmliebhaber bietet.

          Wir laden Sie ein, unsere Datenbank zu erforschen, neue Titel zu entdecken und versteckte Filmperlen zu entdecken. Lassen Sie die Kinematographie zu einer noch faszinierenderen Welt werden, die Sie erkunden können!
        </p>

        <h3 class="m-t-16 p2">
          Schauspieler-Datenbank
        </h3>
        <p class="p3 m-t-8">
          Schauspieler sind das Herz und die Seele eines Films. Bei cinetixx Filme laden wir Sie dazu ein, Informationen über Ihre Lieblingskünstler zu entdecken. Bei uns finden Sie heraus, in welchen Filmen sie mitgewirkt haben, mit wem sie gearbeitet haben und welche Rollen sie gespielt haben. Von den größten Stars der Welt bis hin zu vielversprechenden Talenten - unsere Datenbank der Schauspieler ist umfangreich und wird ständig aktualisiert.

          Mit unserer Ressource können Sie die Filmografie Ihrer Lieblingsschauspieler erkunden und herausfinden, mit wem sie das Vergnügen hatten, zusammenzuarbeiten und in welchen Produktionen sie ihre denkwürdigen Auftritte hatten. Ganz gleich, ob Sie sich für große Hollywood-Produktionen oder intimere, unabhängige Filme interessieren, unsere Schauspieler-Datenbank bietet Ihnen einen umfassenden Einblick in ihre Karriere und ihre Arbeit.

          cinetixx Filme achtet darauf, dass unsere Datenbank nicht nur umfassend, sondern auch immer aktuell ist, so dass wir regelmäßig neue Informationen über Filme und Schauspieler hinzufügen. Mit uns können Sie Ihr Wissen über Ihre Lieblingskünstler und ihr filmisches Schaffen vertiefen, was das Ansehen von Filmen zu einem noch faszinierenderen Erlebnis macht. Wir laden Sie ein, unsere Datenbank mit Schauspielern zu erkunden und ihre außergewöhnlichen Werke zu entdecken!
        </p>

        <h3 class="m-t-16 p2">
          Kino-Datenbank
        </h3>
        <p class="p3 m-t-8">
          Planen Sie bald einen Kinobesuch? Ob Sie nun Lust auf eine große Premiere in einem hochmodernen Kinosaal haben oder die Atmosphäre eines kleinen, gemütlichen Kinos erleben möchten, in unserer Kinodatenbank finden Sie alle Informationen, die Sie brauchen. Wir von cinetixx Filme laden Sie ein, sich über das Programm der verschiedenen Kinos zu informieren, Ihren Lieblingssaal auszuwählen, die aktuellen Filme zu sehen und Ihre Tickets online zu buchen.

          Dank unserer Plattform können Sie ganz einfach herausfinden, welches Kino in Ihrer Nähe die neuesten Blockbuster zeigt und welches sich auf die Vorführung von Independent-Filmen oder Klassikern spezialisiert hat. Unsere Datenbank bietet eine Vielzahl von Informationen über Kinos, vom Standort bis zu den Vorführzeiten.

          Mit cinetixx Filme können Sie Ihren Kinobesuch ganz einfach und bequem planen. Sie müssen nicht mehr mehrere Websites durchsuchen - alle Informationen, die Sie benötigen, finden Sie bei uns. Planen Sie Ihren Filmabend jetzt mit unserer Kinodatenbank!
        </p>

        <h3 class="m-t-16 p2">
          Kino-News
        </h3>
        <p class="p3 m-t-8">
          Wir sind hier, um Sie mit den neuesten Informationen über Kinopremieren zu versorgen. Besuchen Sie unsere Website regelmäßig, um über die heißesten Blockbuster auf dem Laufenden zu bleiben. Ob Sie sich für neue Blockbuster, bewegende Dramen oder lustige Animationsfilme für die ganze Familie interessieren, auf unserer Website finden Sie immer die neuesten Premieren.

          Wir stellen komplette Listen der neuesten Filme zusammen, mit kurzen Beschreibungen der Handlung und Trailern. So können Sie schnell und einfach herausfinden, welche Filme es in nächster Zeit zu sehen gibt.

          cinetixx Filme ist Ihre Quelle für die neuesten Informationen zu Filmpremieren. Verpassen Sie keine wichtige Premiere - behalten Sie unsere Website im Auge und bleiben Sie auf dem Laufenden über die faszinierende Welt des Kinos!
        </p>

        <h3 class="m-t-16 p2">
          Produzenten-Datenbank
        </h3>
        <p class="p3 m-t-8">
          Produzenten-Datenbank

          Produzenten sind ein fester Bestandteil der Hinterbühne der Filmwelt. Bei cinetixx Filme laden wir Sie herzlich ein, Informationen über Ihre Lieblingsfilmemacher zu entdecken. Hier können Sie herausfinden, mit welchen Projekten sie sich identifiziert haben, welche Filme sie produziert haben und mit wem sie zusammengearbeitet haben. Von den bekanntesten Produzenten bis hin zu den aufstrebenden Stars der Filmindustrie - unsere Produzentendatenbank ist umfangreich und wird ständig aktualisiert.

          Mit unseren Ressourcen können Sie die kreative Geschichte Ihrer Lieblingsproduzenten erforschen und mehr über ihre Leistungen in der Welt des Films erfahren. Ganz gleich, ob Sie sich für große Hollywood-Produktionen oder für unabhängige Nischenprojekte interessieren, unsere Produzentendatenbank bietet Ihnen ein umfassendes Bild ihrer Karrieren und ihres Beitrags zum Kino.

          cinetixx Filme sorgt dafür, dass unsere Datenbank nicht nur umfassend, sondern auch immer aktuell ist. Wir fügen regelmäßig neue Informationen über Projekte und Produzenten hinzu, damit Sie über die neuesten Entwicklungen in der Filmbranche auf dem Laufenden bleiben können. Dank uns können Sie Ihr Wissen über Filmemacher und ihre Beiträge zur Welt des Kinos vertiefen und Ihr Filmerlebnis noch spannender gestalten. Wir laden Sie ein, unsere Datenbank der Produzenten zu erkunden und mehr über ihre außergewöhnlichen Werke zu erfahren!
        </p>

        <h3 class="m-t-16 p2">
          cinetixx Filme - Ihr Kino, Ihre Gefühle!
        </h3>
        <p class="p3 m-t-8">
          Lassen Sie sich vom Film in eine Welt voller Emotionen, Abenteuer und unvergesslicher Momente führen. cinetixx Filme ist mehr als nur eine Website - es ist eine Gemeinschaft von leidenschaftlichen Filmliebhabern, die bereit sind, ihre Eindrücke und Empfehlungen zu teilen. Werden Sie noch heute Mitglied und tauchen Sie ein in die faszinierende Welt des Films auf <b>cinetixx Filme</b>! 🎬🍿
        </p>
      </app-expand-text>
    </div>
  </div>

  <cx-footer [items]="footerItems" class="d-block"></cx-footer>
</div>

<app-cookies
  #cookies
  colorBrand="#0d6ebc"
  link1Href="https://cinetixx.de/impressum.html"
  link2Href="https://cinetixx.de/datenschutz.html"
  link1Text="Impressum"
  link2Text="Datenschutzerklärung"
  moreInformationLinkHref="https://cinetixx.de/datenschutz.html"
></app-cookies>
