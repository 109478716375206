export const environment = {
  production: true,
  siteName: 'cinetixx Filme',
  apiUrl: 'https://api.backend.cinetixx.com',
  postersUrl: 'https://images.cinetixx.com/posters',
  scenesUrl: 'https://images.cinetixx.com/scenes',
  defaultDateFormat: 'dd.MM.yyyy',
  servicesDateFormat: 'YYYY-MM-DD',
  servicesDateTimeFormat: 'YYYY-MM-DD HH:mm',
  bookingUrl: 'https://booking.cinetixx.de/frontend',
  baseUrl: 'https://movies.cinetixx.de'
};
