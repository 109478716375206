<div
  class="expand-text-container position-relative"
  [class.active]="isActive"
>
  <div class="expand-text-container__inner">
    <ng-content></ng-content>
  </div>
  <div class="expand-text-container__box full-width position-absolute">
    <button
      class="link-button"
      (click)="isActive = !isActive"
    >
      Mehr sehen
    </button>
  </div>
</div>
